<template>
  <div class="bigbox">
    <div v-show="isshow">
      <div>
        <van-nav-bar title="充值"
                     left-arrow
                     @click-left="$router.back()" />
      </div>
      <div class="iptbox">
        <span style="font-size:14px;color:#000000">充值账号</span>
        <input v-model="twophone"
               style="margin-left:10px;height:38px;line-height:38px ;"
               type="text"
               placeholder="请输入充值账号" />
        <span @click="automatic">自动填入</span>
      </div>
      <div class="fontbox">
        当前登录账号：
        <span>{{this.phone}}</span>
      </div>
      <div class="iptbox">
        <span style="font-size:14px;color:#000000">充值金额</span>
        <input v-model="money"
               style="margin-left:10px;height:38px;line-height:38px ;"
               type="text"
               placeholder="请输入充值金额" />
      </div>
      <div class="carbox">
        <div style="line-height: 25px;">
          <div style="font-size:14px;color:#000000">账户余额（元）</div>
          <div style="font-size:20px;font-weight:bold">{{this.integral}}</div>
        </div>
        <img src="../assets/images/wt.png"
             alt />
      </div>
      <div class="footbox">
        <div @click="goorder"
             style="display:flex;justify-content: space-between; align-items: center;margin:0 20px;height:50px">
          <div style="font-size:14px;color:#000000">充值记录</div>
          <img style="width:10px;height:15px"
               src="../assets/images/right.png"
               alt />
        </div>
        <!-- <div
          class="border"
          style="display:flex;border-top:solid 1ppx #eee;justify-content: space-between; align-items: center;margin:0 20px;height:50px"
        >
          <div style="font-size:14px;color:#000000">充值活动</div>
          <img style="width:10px;height:15px" src="../assets/images/right.png" alt />
        </div> -->
      </div>
      <button class="btn"
              @click="ok">提交</button>
    </div>
  </div>
</template>
<script>
import { wechat_api, loadstatus_api } from '../http/api/pay'
import { newinfo_api } from '../http/api/certification'
import { Toast } from 'vant'
export default {
  name: 'TaxiH5Rech',
  data () {
    return {
      phone: '',
      twophone: '',
      money: '',
      token: '',
      integral: '',
      isshow: true,
      orderNum: null,
      openid: '',
      mic: true,
      payParam: {}
    }
  },
  created () {
    this.openid = localStorage.getItem('openid')
    if (this.openid == null) {
      this.openid = ''
    }
  },
  mounted () {
    var ua = window.navigator.userAgent.toLowerCase()
    if (ua.match(/MicroMessenger/i) == 'micromessenger') {
      this.mic = false
    }

    this.newinfo()
    // Toast('请使用外部浏览器打开，并充值')
    // let token = JSON.parse(sessionStorage.getItem('token'))
    // this.token = token.token
    // this.phone = token.loginName
    // this.integral = token.integral
    // this.phone=JSON.parse(sessionStorage.getItem('token')).loginName
    // this.orderNum = localStorage.getItem('orderNum')
    //  if(this.orderNum!=null){
    //     this.orderstatus()

    //  }
  },

  methods: {
    //充值记录
    goorder () {
      this.$router.push({
        path: '/Rechorder',
      })
    },
    //最新用户信息
    async newinfo () {
      let res = await newinfo_api({})
      if (res.code == 200) {
        // console.log(res.data.headImg)
        // var img = JSON.parse(sessionStorage.getItem('token'))
        // img.userName = res.data.userName
        // img.integral = res.data.integral
        // sessionStorage.setItem('token', JSON.stringify(img))
        this.integral = res.data.integral
        this.phone = res.data.phone
      }
    },
    automatic () {
      this.twophone = this.phone
    },
    ok () {
      this.wechatPayMethord()
      //this.is_weixin()
    },

    async wechatPayMethord () {
      //let nowOrder = JSON.parse(sessionStorage.getItem('nowOrder'))
      let res = await wechat_api({
        phone: this.twophone,
        money: this.money,
        openId: this.openid,
      })

      if (res.code == 200) {
        this.payParam = res.data
        // alert(res.data.toString)
        localStorage.setItem('orderNum', res.data.orderNum)
        // var timerID= setInterval(() => {
        //    this.orderstatus()
        //  }, 3000);
        //  console.log(timerID);
        if (this.mic) {
          //外部浏览器支付
          let redirect_url = '&redirect_url=https://mihuatang.xyz/Ok_rech'
          console.log(redirect_url)
          location.href = res.data.url + redirect_url
        } else {
          this.wxpay(res.data, function (payResult) {
            console.log(payResult)
          })
        }
        // setTimeout(() => {
        //   this.$router.push({
        //     path: '/Ok_rech',
        //   })
        // }, 5000)
      }
    },
    async orderstatus () {
      console.log(111111)
      let res = await loadstatus_api({
        orderNum: localStorage.getItem('orderNum'),
      })
      console.log(res.data)
      if (res.data == true) {
        localStorage.removeItem('orderNum')
        // clearInterval(this.timerID);
        this.$router.push({
          path: '/Ok_rech',
        })
      }
    },
    //微信浏览器支付
    wxpay (params, callback) {
      if (typeof window.WeixinJSBridge == 'undefined') {
        if (document.addEventListener) {
          document.addEventListener(
            'WeixinJSBridgeReady',
            this.onBridgeReady(params, callback),
            false
          )
        } else if (document.attachEvent) {
          document.attachEvent(
            'WeixinJSBridgeReady',
            this.onBridgeReady(params, callback)
          )
          document.attachEvent(
            'onWeixinJSBridgeReady',
            this.onBridgeReady(params, callback)
          )
        }
      } else {
        this.onBridgeReady(params, callback)
      }
    },
    onBridgeReady (params, callback) {
      console.log(params);
      console.log(callback);
      var that = this
      console.log(that)
      window.WeixinJSBridge.invoke(
        'getBrandWCPayRequest',
        {
          appId: 'wx992b6dfbb02b02a1',
          timeStamp: this.payParam.timeStamp,
          nonceStr: this.payParam.nonceStr,
          package: this.payParam.package,
          signType: this.payParam.signType,
          paySign: this.payParam.sign,
        },
        function (res) {

          if (res.err_msg == "get_brand_wcpay_request:ok") {
            // this.$router.push({
            //   path: '/Ok_rech',
            // })
            Toast({
              message: '支付成功',
              position: 'middle',
              duration: 3000
            });
            // this.$router.push({
            //   path: '/Ok_rech',
            // })
            window.location.href = 'https://mihuatang.xyz/Ok_rech'
          } else {
            Toast({
              message: '支付失败',
              position: 'middle',
              duration: 3000
            });
            window.location.href = 'https://mihuatang.xyz/Ok_rech'
          }

          //callback(res)
        }
      )
    },
  },
}
</script>

<style lang="scss" scoped>
.bigbox {
  width: 100%;
  height: 100vh;
  background: #eee;
  .wxtip {
    background: rgba(0, 0, 0, 0.8);
    text-align: center;
    position: fixed;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    z-index: 998;
    //display: none;
  }
  .iptbox {
    margin-top: 10px;
    height: 50px;
    background: #fff;
    line-height: 50px;
    padding-left: 20px;

    input {
      border: 0;
    }
  }
  .fontbox {
    height: 20px;
    line-height: 40px;
    margin-left: 20px;
    color: #333;
  }
  .carbox {
    margin-top: 20px;
    height: 100px;
    display: flex;
    background: #fff;
    justify-content: space-between;
    padding: 0 20px;
    align-items: center;
  }
  .footbox {
    margin-top: 10px;
    //height: 100px;
    height: 50px;
    background: #fff;
    .border {
      border-top: solid 1px #eee;
    }
  }
  .btn {
    width: 90%;
    height: 40px;
    background: #eb4541;
    margin: 50px 5%;
    border-radius: 15px;
    border: 0;
    color: #fff;
    font-size: 14px;
  }
}
</style>